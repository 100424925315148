import React from 'react'
import { CMSWrapper } from '../components/cmsWrapper'
import { Navigation } from '../common/navigation'
import { Icon } from '../common/icon'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { Footer } from '../common/footer'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useFavoritesContext } from '../common/contexts/favoritesContext'
import { useAreaContext } from '../common/contexts/areaContext'
import { Button } from '../common/button'

export default function Favoriten({ location }) {
  const {
    favorites,
    removeAllFavorites,
    toggleFavorite,
  } = useFavoritesContext()
  const basePath = useAreaContext()
  const [showSavedAlert, setShowSavedAlert] = React.useState(false)

  const data = useStaticQuery(graphql`
    {
      allStation {
        nodes {
          introText
          title
          slogan
          slug
          stationId
          hasVacancies
        }
      }
    }
  `)
  const favoriteStations = data.allStation.nodes.filter((station) => {
    return favorites.includes(station.stationId)
  })

  const saveFavoritesAsLink = React.useCallback(() => {
    let link = `${location.href}?`
    favorites.forEach(
      (fav, i) => (link += `${fav}${i < favorites.length - 1 ? '&' : ''}`)
    )
    navigator.clipboard.writeText(link)
    setShowSavedAlert(true)
    setTimeout(() => {
      setShowSavedAlert(false)
    }, 2000)
  }, [favorites, location.href])

  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Meine Favoriten" />
      <main>
        <Navigation />
        <section id="stations" className="bg-blue divider-left">
          {!favoriteStations || favoriteStations.length < 1 ? (
            <div id="empty-state" className="row align-items-center mt-5 mb-5">
              <div className="col-md-2 offset-md-1 col-12 text-center">
                <img
                  src="/img/icons/icon-fav.png"
                  alt="Icon: Favoriten"
                  height="40"
                  width="40"
                />
              </div>
              <div className="col-md-8 col-12">
                <p className="mb-0 ">
                  Bisher hast du noch keine Favoriten. Stöber doch ein wenig
                  durch unsere Bereiche oder durch deine persönlichen Top
                  Ten-Liste und merke dir deine liebsten Bereiche.
                </p>
              </div>
              {basePath.length > 2 && (
                <div className="col-12 text-center">
                  <Button
                    color="yellow"
                    href={`${basePath}/stationen`}
                    className="mt-4"
                  >
                    Stöbern
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="row" id="favouritesList">
                <div className="col-md-10 offset-md-1 col-12">
                  <h2 className="d-inline-block bg-dark-blue">
                    Deine Favoriten
                  </h2>
                  <ul id="operativeMedizin" className="link-list">
                    {favoriteStations.map((favoriteStation) => (
                      <li
                        className={`mt-5 d-flex job-${favoriteStation.hasVacancies ? 'green' : 'red'
                          }`}
                        key={favoriteStation.slug}
                      >
                        <div className="row align-items-center">
                          <div className="col-md-11 col-10">
                            <h4>
                              <Link
                                className="bg-dark-blue"
                                to={favoriteStation.slug}
                                state={{
                                  carouselTitle: 'Deine Favoriten',
                                  carouselSlugs: favoriteStations.map(
                                    (station) => station.slug
                                  ),
                                }}
                              >
                                {favoriteStation.title}
                              </Link>
                            </h4>
                            <p className="mb-0">
                              {favoriteStation.introText}
                            </p>
                          </div>
                          <div className="col-md-1 col-2">
                            <button
                              onClick={() =>
                                toggleFavorite(favoriteStation.stationId)
                              }
                            >
                              <Icon name="delete" height="25" width="25" />
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <ul
                id="job-indicator"
                className="col-md-10 offset-md-1 col-12 mt-5"
              >
                <li className="job-green">Wir haben eine freie Stelle</li>
                <li className="job-red">
                  Wir haben zur Zeit leider keine freie Stelle
                </li>
              </ul>
            </>
          )}
        </section>
        {favoriteStations && favoriteStations.length > 0 ? (
          <section>
            <div id="buttons" className="row text-center mt-5 mb-5">
              <div className="col-md-10 offset-md-1 col-12 mt-2">
                <Button
                  id="save-link"
                  color="dark-blue"
                  className="clipboard"
                  onClick={saveFavoritesAsLink}
                >
                  Favoriten als Link kopieren
                </Button>

                {showSavedAlert && (
                  <input
                    type="text"
                    id="copyAlert"
                    value="Link gespeichert"
                    style={{ position: 'absolute' }}
                    readOnly
                  />
                )}
                <p className="mt-2">
                  um deine Favoritenliste zu speichern und auch auf anderen
                  Geräten einsehen zu können.
                </p>
              </div>

              <div className="col-md-10 offset-md-1 col-12 mt-2">
                <Button
                  color="dark-blue"
                  className="mt-4"
                  onClick={removeAllFavorites}
                >
                  Favoriten löschen
                </Button>
                <p className="mt-2">um deine Favoritenliste zu löschen.</p>
              </div>
            </div>
          </section>
        ) : null}
        <Footer />
      </main>
    </CMSWrapper>
  )
}
